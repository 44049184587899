:root{
    --planet-details-collapsed-height: 7rem;
    --planet-details-expanded-height: 20rem;
    --planet-details-expanded-height-responsive: 30rem;
}

.App {
    color: var(--color-white);
    background-color: var(--color-black);
    min-height: 100vmin;
}

.App-header {
    background: var(--color-black);
    font-size: 1.7rem;
    color: var(--color-white);
    border-bottom: .1em solid transparent;
    border-image: linear-gradient(to right, var(--color-magenta), var(--color-purple)) 1;
}

.title {
    text-align: center;
    font-size: 2.4rem;
    font-weight: 1000;
    margin: 0 .7rem 0.3rem .7rem;
}

.border-gradient {
    background: linear-gradient(var(--color-black), var(--color-black)) padding-box,
    linear-gradient(135deg, var(--color-magenta), var(--color-purple)) border-box;
}

.planets-list {
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 40rem;
    min-width: 300px;
}

.planets-list * {
    margin-left: auto;
    margin-right: auto;
}

.planets-list-container {
}

.planet-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: .5rem;
    width: 97%;
    background-color: var(--color-black);
    color: var(--color-white);
    padding: 1rem;

    border: .2em solid transparent;
    border-radius: 2rem;
}

.planet-details:hover {
    background-color: var(--color-grey);
    border: .4em solid transparent;
}

.planet-details-expanded {
    animation-name: expand-with-overshoot;
    animation-duration: .3s;
    animation-timing-function: ease-in-out;
    height: var(--planet-details-expanded-height);
}

.planet-details-header{
    width: 100%;
    display: block;
    height: 5rem;
}

.planet-details-collapsed {
    animation-name: collapse-with-overshoot;
    animation-duration: .3s;
    animation-timing-function: ease-in-out;
    height: var(--planet-details-collapsed-height);
}


.planet-icon {
    display: inline-block;
    margin-top: 0.3rem;
    margin-left: 1rem;
    height: 4rem;
}

.planet-label {
    position: relative;
    top: -4rem;
    margin-top: 0;
    margin-left: 5rem;
    display: inline-block;
    width: calc(100% - 6rem) ;
    text-align: right;
    font-size: 2.5rem;
}

.planet-details-expanded.planet-label .planet-details-expanded.planet-details-image{
    top:-2rem;
}

.empty {
    display: none;
}


.planet-details-section {
    text-align: center;
    height: 10rem;
    width: 100%;
    position: relative;
    top: 1rem;
}

.planet-details-section * {
    opacity: inherit;
}

.planet-details-info {
    margin-top: 2rem;
    display: inline-block;
    height: 10rem;
    position: relative;
    top: -3rem;
    text-align: left;
}

.planet-details-info-title {
    margin-left: 2rem;
}

.planet-details-section-expanded {
    animation-name: slide-in-with-overshoot;
    animation-duration: .4s;
    animation-timing-function: ease-in-out;
    opacity: 100%;
}

.planet-details-section-collapsed {
    animation-name: slide-out;
    animation-duration: .4s;
    animation-timing-function: ease-in-out;
    opacity: 0;
}

li {
    line-height: 1.5;
}

.divider {
    position: relative;
    top: .3rem;
    border: .1rem solid var(--color-white);
    border-radius: .2rem;
    width: 100%;
    animation-name: pop-in;
    animation-duration: .2s;
    animation-timing-function: ease-in-out;
    margin-bottom: 1.5rem;
}

.divider-collapsed {
    position: relative;
    top: .3rem;
    border: .1rem solid var(--color-white);
    border-radius: .2rem;
    width: 0;
    animation-name: pop-out;
    animation-duration: .2s;
    animation-timing-function: ease-in-out;
    visibility: hidden;
}

.planet-details-image {
    border: .2em solid var(--color-grey);
    border-radius: 1rem;
    height: 10rem;
    position: relative;
    top: -1rem;
}


@media (max-width: 550px) {
    .planet-details-expanded {
        height: var(--planet-details-expanded-height-responsive);
        animation-name: expand-with-overshoot-responsive;
    }
}

@keyframes expand-with-overshoot {
    0% {
        height: var(--planet-details-collapsed-height);
    }
    70% {
        height: calc(var(--planet-details-expanded-height) + .5rem) ;
    }

    90% {
        height: calc(var(--planet-details-expanded-height) - .25rem) ;
    }

    100% {
        height: var(--planet-details-expanded-height);
    }
}

@keyframes collapse-with-overshoot {
    0% {
        height: var(--planet-details-expanded-height);
    }

    70% {
        height: calc(var(--planet-details-collapsed-height) - .5rem) ;
    }

    90% {
        height: calc(var(--planet-details-collapsed-height) + .25rem) ;
    }

    100% {
        height: var(--planet-details-collapsed-height);
    }
}

@keyframes expand-with-overshoot-responsive {
    0% {
        height: var(--planet-details-collapsed-height);
    }
    70% {
        height: calc(var(--planet-details-expanded-height-responsive) + .5rem) ;
    }

    90% {
        height: calc(var(--planet-details-expanded-height-responsive) - .25rem) ;
    }

    100% {
        height: var(--planet-details-expanded-height-responsive);
    }
}

@keyframes collapse-with-overshoot-responsive {
    0% {
        height: var(--planet-details-expanded-height-responsive);
    }

    70% {
        height: calc(var(--planet-details-collapsed-height) - .5rem) ;
    }

    90% {
        height: calc(var(--planet-details-collapsed-height) + .25rem) ;
    }

    100% {
        height: var(--planet-details-collapsed-height);
    }
}

@keyframes slide-in-with-overshoot-responsive {
    0% {
        top: var(--planet-details-expanded-height);
        opacity: 0;
    }

    70% {
        top: -.5rem ;
    }

    90% {
        top: 0.25rem;
    }

    100% {
        opacity: 100%;
        top: 0;
    }
}

@keyframes slide-out {

    from {
        opacity: 100%;
        top: 0;
    }

    to {
        opacity: 0;
        top: var(--planet-details-expanded-height);
    }
}

@keyframes pop-in {
    0% {
        width: 0;
    }

    70% {
        width: 101%;
    }

    90% {
        width: 99%;
    }

    100% {
        width: 100%;
    }
}

@keyframes pop-out {
    from {
        width: 100%;
        visibility: visible;
    }

    to {
        width: 0;
        visibility: hidden;
    }
}